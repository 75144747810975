@use "../../../../assets/styles/_variables";

.qr-code-reader--wrapper {
  background: white;
  padding: 5px 10px 10px;
  border-radius: 6px;
  max-width: 500px;
  width: 100%;

  .qr-code-reader-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 5px;

    h2 {
      margin: 0;
    }
  }

  .qr-code-reader--buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    button {
      margin-top: 10px;
    }

    @media screen and (max-width: 480px) {
      button {
        width: 100%;
      }
    }
  }

  .qr-code-reader {
    position: relative;

    section > div {
      border-radius: 6px;
    }
  }

  .qr-code-container {
    display: flex;
    width: 100%;
    aspect-ratio: 1 / 1;
    justify-content: center;
    overflow: hidden;
    align-items: center;
  }

  .qr-code-video {
    transform: unset;

    @media screen and (min-width: 351px) and (max-width: 400px) {
      transform: scale(0.8);
    }

    @media screen and (min-width: 326px) and (max-width: 350px) {
      transform: scale(0.7);
    }

    @media screen and (max-width: 325px) {
      transform: scale(0.6);
    }
  }
}