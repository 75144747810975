@use "../../../../assets/styles/_variables";

$width : 300px;

.userguide-settings-entry {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	
	.further-options-info {
		height: 25px;
	}

	.dropdown-heading {
		margin: 20px 0;
		text-align: center;
	}
	
	.dropdown-button {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 5px 5px 5px 10px;
		max-width: $width;
		width: 100%;
		height: 45px;
		background: variables.$white;
		font-weight: bold;
		color: variables.$font-main;
		cursor: pointer;
		justify-content: space-between;
		border-radius: 6px;
		
		h3 {
			font-size: 16px;
			margin: 0;
		}

		&.arrow-no-rot {
			img {
				transform: rotate(0deg);
			}
		}

		&.hide-label {
			color: transparent;
		}

		.arrow-icon {
			width: 35px;
			transform: rotate(90deg);
		}

		&.active {
			border-radius: 6px;

			img {
				transform: rotate(0deg);
			}
		}

		&.selectedValue {
			color: variables.$font-highlight;
		}

		&.disabled {
			opacity: 0.7;

			img {
				visibility: hidden;
			}
		}


		.device-icon {
			margin-right: 5px;
		}
	}

	.entry-dropdown {

		::-webkit-scrollbar {
			width: 10px;
		}

		::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-radius: 0 0 6px 0;
		}

		::-webkit-scrollbar-thumb {
			background: variables.$purple;
			border-radius: 5px;
		}

		::-webkit-scrollbar-thumb:hover {
			background: darkgrey;
		}


		.dropdown-content {
			overflow-y: auto;
			max-height: 202px;
			border-radius: 6px;
			position: absolute;
			max-width: $width;
			width: 100%;
			margin-top: -20px;
			transform: translate(-50%, 0);
			box-shadow: 5px 10px 45px #5a5a5a;

			.dropdown-item {
				padding: 10px;
				color: variables.$font-main;
				cursor: pointer;
				transition: all 0.2s;
				background: white;
				border-top: 1px solid variables.$hr-secondary;

				&:hover {
					background: #eeeeee;
				}

				&.dropdown-item-last {
					border-radius: 0 0 0 0;
				}
			}
		}
	}

}
