@use "../../../../assets/styles/_variables";

.progressbarWrapper {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  color: variables.$font-main;
  background-color: var(--background-color);
  max-width: 906px;

  .progressbarHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h3 {
      margin: 0;
      font-weight: bold;
    }
    
    @media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
      flex-direction: column;
    }

    .progressbarHeading {
      @media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
        width: 100%;
        text-align: center;
        font-size: 12px;
        margin-top: 40px;
      }
    }
  }

  .progressbarStepContainer {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
      flex-direction: column;
      margin-top: 20px;
    }
  }

  .progressbarStep {
    width: 30%;

    @media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
      width: 100%;
    }
  }
}


