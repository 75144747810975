@use "../../../../../assets/styles/_variables";

$breakpoint: 900px;

.geoLocationModalContent {

  padding-bottom: 20px;

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
      text-align: unset;
    }
  }

  .geoLocationInfo {
    display: grid;
    gap: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
}