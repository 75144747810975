@use "../../../../assets/styles/_variables";

$background-card-grey: #f0f0f0;

	.userguide-content-entry {
		background-color: variables.$background-content;
		border-left: 5px solid variables.$purple;
		border-radius: 6px;
		cursor: pointer;

		h1, h2, h3, h4, h5, h6, p, a {
			color: variables.$font-main
		}
		
		a {
			word-break: break-all;
		}

		.userguide-header-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: 10px;
			width: 95%;
			
			img {
				transform: rotate(-90deg);

				&.active {
					transform: none;
				}
			}
		}

		hr {
			width: 98%;
			margin-left: 1% !important;
			border: none;
			margin: 3px 0;
			border-bottom: 2px solid var(--separator-color);
			
			&.hidden{
				display: none;
			} 
		}

		.userguide-card-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			flex: 1 0 calc(32% - 10px);
			padding-bottom: 15px;
			
			&.hidden {
				display: none;
			}

			.userguide-card {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: calc((100%) / 3);
				padding: 0 20px;
				
				@media screen and (max-width: variables.$breakpoint-userguide-to-one-columns) {
					width: 100%;
					height: unset;
				}
				
				@media screen and (max-width: variables.$breakpoint-userguide-to-two-columns) and (min-width: variables.$breakpoint-userguide-to-one-columns){
					width: calc((100%) / 2);
				}

				.userguide-card-img-wrapper {
					width: 100%;
					height: 250px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $background-card-grey;

					.userguide-card-img {
						max-width: 96%;
						max-height: 240px;
						object-fit: cover;
						overflow: hidden;
					}
				}
			}
		}
	}
