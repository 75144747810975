@use "../../../../assets/styles/_variables";

.digit {
  background-color: var(--digit-background-color);
  max-width: var(--digit-width);
  height: var(--digit-height);
  border: var(--digit-border-default);
  border-radius: var(--digit-border-radius);
  text-align: center;
  font-size: 16px;

  &:focus-visible {
    outline: none;
    border: var(--digit-border-focus);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}
