@use "../../../../assets/styles/variables";

.progressbarStepWrapper {
  font-family: var(--font-family);
	color: variables.$font-main;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--background-color);
	height: 150px;
	max-width: 285px;

	@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
		height: 45px;
		max-width: none;
	}
}

.progressbarStepImage {
	display: flex;
	align-items: flex-end;
	height: 110px;

	@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
		display: none;
	}
}

.progressbarStepInformation {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: variables.$yellow;
	border-radius: 6px;
	padding: 0 15px;
	height: 40px;

	&.active {
		background-color: variables.$purple;

		p {
			color: variables.$yellow !important;
		}
	}

	p {
		font-size: 12px;
	}

	.checkmark {
		height: 12px;
	}
}

.progressbarStepImage img {
	height: 110px;
}
