@use "../../../../assets/styles/_variables";

.newly-added-device {
  display: flex;
  flex-direction: column;
  background-color: variables.$background-content;
  font-family: Lato, sans-serif;
  color: variables.$font-secondary;
  padding: 10px;
  border-radius: 10px;
  max-width: 906px;
  width: 100%;
}

.newly-added-device--row {
  display: flex;
  align-items: center;
  gap: 20px;


  img {
      width: variables.$icon-size;
      height: variables.$icon-size;
  }
}

.newly-added-device--title {
  margin-left: variables.$icon-size + 20px;
  font-weight: bold;
}

.newly-added-device--device-name * {
  color: variables.$black;
}

hr {
  width: 100%;
  border: none;

  &.light {
    border-bottom: 2px solid variables.$hr-secondary;
  }

  &.dark {
    border-bottom: 2px solid variables.$hr-main;
  }
}

.might-overflow {
  text-overflow: ellipsis;
  overflow : hidden;
  white-space: nowrap;
}