@use "../../../../../assets/styles/_variables";


.userguide-card-pretext {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: calc((100%) / 3);
  cursor: default;

  @media screen and (max-width: variables.$breakpoint-userguide-to-one-columns) {
    height: unset;
    width: 100%;
  }

  @media screen and (max-width: variables.$breakpoint-userguide-to-two-columns) and (min-width: variables.$breakpoint-userguide-to-one-columns) {
    width: calc((100%) / 2);
  }
}