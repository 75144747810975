@use "../../../../assets/styles/_variables";

.loading-image-child {
  width: 100%;
  margin-top: 20px;
  background-color: variables.$info-modal-bg;
  color: variables.$font-main;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;

  .loading-image-child-paragraph {
    margin: 0;
    color: variables.$font-main !important;
    text-align: left!important;
  }

  .loading-image-child-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%!important;
      height: 100%;
      max-width: 500px;

      @media screen and (max-height: 700px) {
        max-height: 50vh !important;
      }

      @media screen and (max-height: 900px) {
        max-height: 65vh;
      }
    }
  }
}
  