@use "../../../../assets/styles/_variables";

.information-qr {
	background: variables.$background-content;
	color: variables.$font-main;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	border-radius: 6px;
	padding: 20px;
	margin-top: 30px;
	max-width: 906px;

	p.note {
		margin: 0;

		a {
			font-size: 12px;
		}
	}

	.information-qr-zone {
		display: flex;
		flex-direction: row;
		align-items: center;

		.qr-code {
			margin-right: 15px;
			margin-bottom: 15px;
		}

		.qr-content {
			margin-bottom: 15px;
		}
	}
}