@use "../../../../assets/styles/_variables";

.checkbox-wrapper {
  .checkbox-input {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    -webkit-user-select: none; /* Safari */
    user-select: none; /* Standard */

    p {
      margin: 0 0 0 8px;
    }

    input {
      accent-color: var(--checkbox-color);
      border: none;
    }
  }

  .checkbox_message {
    align-self: flex-start;
    margin-top: 10px;
    width: fit-content;

    .checkbox_text {
      border-radius: 5px;
      font-size: 12px;
      margin: 0;
      min-width: 48px;
      padding: 8px;

      &.error {
        background-color: var(--error-background-color);
        color: var(--error-link-color);
      }

      &.warning {
        background-color: var(--warning-background-color);
        color: var(--warning-link-color);
      }
    }

    .checkbox_corner {
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      height: 0;
      position: absolute;
      transform: translate(4px, -7px);
      width: 0;

      &.error {
        border-bottom: 7px solid var(--error-background-color);
      }

      &.warning {
        border-bottom: 7px solid var(--warning-background-color);
      }
    }
  }
}