@use "../../../../assets/styles/_variables";

.locked {
	background-color: rgba(0, 0, 0, .5) !important;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;
}

.ug-image-modal-wrapper {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	@media screen and (max-width: variables.$screensize-sm) {
		width: 90%;
	}

	.ug-image-modal {
		background-color: variables.$info-modal-bg;
		color: variables.$info-modal-font;
		padding: 20px;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		overflow-wrap: anywhere;
		box-shadow: 0 0 20px 0 variables.$darkgray;
		gap: 5px;
		max-height: 90vh;

		.ug-image-modal-header {
			display: flex;
			justify-content: space-between;
			gap: 10px;
			margin-bottom: 5px;
			
			.nowrap-paragraph {
				white-space: nowrap;
			}
			
			p {
				margin-top: 0;
			}
			
			a {
				word-break: break-all;
			}
		}
		
		.ug-image-modal-content {
			display: flex;
			align-items: center;
			justify-content: center;
			
			img {
				max-width: 100%;
				
				
				@media screen and (max-height: 700px) {
					max-height: 50vh!important;
				}

				@media screen and (max-height: 900px) {
					max-height: 65vh;
				}
				
			}
		}
		
		.ug-image-modal-footer {
			height: 10%;
		}
	}
}






.userguide-fullscreen-img-modal--icon,
.userguide-fullscreen-img-modal--close-button {
	width: 20px;
	height: 20px;
}

.userguide-fullscreen-img-modal--close-button:hover {
	cursor: pointer;
}

.userguide-fullscreen-img-modal--buttons {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	
	&.firstImg {
		justify-content: flex-end;
	}
}
