@use "../../../../../assets/styles/variables";

.modaldetails {
  margin-top: 10px;
  background-color: #c5bbdc61;
  border-left: 5px solid #4C338D;
  border-radius: 6px;
  padding: 5px 10px;
  gap: 10px;
  flex-direction: row;
  width: 100%;

  .headingwrapper {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
    }

    h3, h4, h5 {
      margin: 0;
      text-align: left;
    }

    .heading {
      cursor: pointer;
    }
  }

  .hidden {
    display: none!important;
  }

  .textwrapper {
    alignment: center;
    border: 1px solid var(--text-color);
    color: var(--text-color);
    padding: 15px;
    width: 100%;
  }

  .modaldetails-header-icon {
    margin-right: 5px;
  }
  
  .modal-details_copy-button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}