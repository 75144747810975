@use "../../../../../assets/styles/_variables";

$breakpoint: 900px;

.pwdGenerateModalContent {

  padding-bottom: 20px;

  .information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
      text-align: unset;
    }
  }

  .pwdInfo {
    display: grid;
    gap: 20px;
    padding-top: 40px;
    padding-bottom: 20px;

    .iconWrapper {
      :active {
        opacity: 0.3;
      }
      transform: unset;
    }

    .icon-size {
      width: 30px;
      height: 30px;

      cursor: pointer;

      @media screen and (max-width: $breakpoint + 250) {
        width: 20px;
        height: 20px;
      }
    }
  }
}