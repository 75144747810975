@use "../../../../assets/styles/variables";

.card {
  background-color: #c5bbdc61;
  border-left: 5px solid #4C338D;
  border-radius: 6px;
  padding: 5px 10px;
  display: flex;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  cursor: pointer;
  margin-top: 10px;

  .toggle-container {
    cursor: pointer;
    display: inline-block;
    animation: fade-out 5s;
    height: 24px;
    width: 24px;

    .line {
      background: variables.$purple;
      display: block;
      height: 2px;
      margin: 5px auto;
      -moz-transition: all .65s ease;
      -ms-transition: all .65s ease;
      -o-transition: all .65s ease;
      -webkit-transition: all .65s ease;
      transition: all .65s ease;
      width: 24px;
    }

    &.inactive {
      .bottom {
        -moz-transform: translateY(0px) rotateZ(90deg);
        -ms-transform: translateY(0px) rotateZ(90deg);
        -o-transform: translateY(0px) rotateZ(90deg);
        -webkit-transform: translateY(0px) rotateZ(90deg);
        transform: translateY(0px) rotateZ(90deg);
      }

      .top {
        -moz-transform: translateY(7px) rotateZ(180deg);
        -ms-transform: translateY(7px) rotateZ(180deg);
        -o-transform: translateY(7px) rotateZ(180deg);
        -webkit-transform: translateY(7px) rotateZ(180deg);
        transform: translateY(7px) rotateZ(180deg);
      }
    }

    &.active {
      .bottom {
        -moz-transform: translateY(0px) rotateZ(180deg);
        -ms-transform: translateY(0px) rotateZ(180deg);
        -o-transform: translateY(0px) rotateZ(180deg);
        -webkit-transform: translateY(0px) rotateZ(180deg);
        transform: translateY(0px) rotateZ(180deg);
      }

      .top {
        -moz-transform: translateY(7px) rotateZ(0deg);
        -ms-transform: translateY(7px) rotateZ(0deg);
        -o-transform: translateY(7px) rotateZ(0deg);
        -webkit-transform: translateY(7px) rotateZ(0deg);
        transform: translateY(7px) rotateZ(0deg);
      }
    }
  }

  .headingwrapper {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;

    h3, h4, h5 {
      margin: 0 0 0 10px;
      text-align: left;
    }
  }

  .hidden {
    display: none !important;
  }

  .imgwrapper {
    @media only screen and (max-width: 450px) {
      width: 100%;
    }

    & {
      width: 30%;
      display: flex;
      justify-content: center;
    }

    img {
      @media only screen and (max-width: 450px) {
        img {
          width: 50%;
        }
      }

      & {
        width: 100%;
        border: 2px solid variables.$purple;
        border-radius: 6px;
        align-self: start;
      }
    }
  }

  .card-header-icon {
    margin-right: 5px;
  }

  .textwrapper {
    @media only screen and (max-width: 450px) {
      width: 100%;
    }

    & {
      flex-direction: row;
      width: 70%;
      display: flex;
      flex: 1;
      align-items: start;
    }

    p {
      margin-top: 0;
    }
  }

  .text-full-with {
    width: 100%;
    flex-direction: column;

    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}