@use "../../../../assets/styles/_variables";

.dropdown {
	width: 100%;
	//height: 100vh;

	&.absolut {
		.dropdown-button {
			width: 200px;
		}
		
		.dropdown-content {
			position: absolute;
			width: 200px;
		}
	}

	::-webkit-scrollbar {
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 0 0 6px 0;
	}

	::-webkit-scrollbar-thumb {
		background: variables.$purple;
		border-radius: 5px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: darkgrey;
	}
	
	.dropdown-button {
		padding: 5px 5px 5px 15px;
		background: variables.$white;
		font-weight: bold;
		color: variables.$font-main;
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: space-between;
		border-radius: 6px;

		&.arrow-no-rot {
			img {
				transform: rotate(0deg);
			}
		}

		&.hide-label {
			color: transparent;
		}
		
		img {
			width: 35px;
			transform: rotate(90deg);
		}

		&.active {
			border-radius: 6px 6px 0 0;

			img {
				transform: rotate(0deg);
			}
		}
		
		&.selectedValue {
			color: variables.$font-highlight;
		}
		
		&.disabled {
			opacity: 0.7;

			img {
				visibility:hidden;
			}
		}
	}
	
	.dropdown-content {
			overflow-y: auto;
			max-height: 202px;
			border-radius: 0 0 6px 6px;
		.dropdown-item {
			padding: 10px;
			color: variables.$font-main;
			cursor: pointer;
			transition: all 0.2s;
			background: white;
			border-top: 1px solid variables.$hr-secondary;
			
			&:hover {
				background: #f4f4f4;
			}
			
			&.dropdown-item-last {
				border-radius: 0 0 0 0;
			}
		}
	}
}

