@use "../../../../assets/styles/_variables";

.stepCircle {
	display: inline-block;
	vertical-align: top;
	height: 26px;
	width: 26px;
	position: relative;

	&:before {
		content: "";
		display: block;
		position: absolute;
		z-index: 2;
		width: 22px;
		height: 22px;
		background: variables.$purple;
		border-radius: 50%;
		top: 2px;
		left: 2px;
	}

	&:after {
		content: "";
		display: block;
		width: 15.6px;
		height: 2px;
		border-radius: 50%;
		margin: 28.6px auto;
	}

	.slice {
		position: absolute;
		width: 26px;
		height: 26px;
		clip: rect(0px, 26px, 26px, 13px);
		animation: bake-pie 1s;
	}

	.slice span {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-color: variables.$white;
		width: 26px;
		height: 26px;
		border-radius: 50%;
		clip: rect(0px, 26px, 26px, 13px);
	}

	.activeSlice span {
		background-color: variables.$yellow;
	}

	.spacer span {
		background-color: variables.$purple;
		
		&.transparent {
			background-color: #6a4bad;
		}
	}

}

.transparent:before {
	background: #6a4bad!important;
}
	
