.userguideAppWrapper {
  display: flex;
  justify-content: center;

  .userguideApp {
    width: 85%;

    @media screen and (max-width: 500px) {
      width: 95%;
    }

    #userguide-header-id {
      margin: 40px 0 60px 0;

      @media screen and (max-width: 500px) {
        width: 70%;
        margin: 20px 0 40px 0;
      }
    }

    .userguide-version-control {
      display: flex;
      flex-direction: column;
      gap: 70px;
      margin-top: 50px;
      margin-bottom: 110px;

      @media only screen and (min-width: 777px) {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
    }
  }

}