@import "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap";
.cs-main-container {
  flex-direction: column;
  flex: 1;
  justify-content: start;
  align-self: center;
  width: 100%;
  max-width: 906px;
  padding: 0 20px;
  display: flex;
}

.userguide-main-container-wrapper, .cs-main-container-wrapper {
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  display: flex;
}

.userguide-main-container {
  width: 100%;
}

.content-item-content {
  width: 100%;
  margin: 10px;
}

.content-item-content-size {
  max-width: 600px;
}

.content-item-icon {
  flex: 50%;
  margin: 10px;
}

@media screen and (width <= 730px) {
  .content-item-icon {
    display: none !important;
  }
}

.content-item-icon img {
  max-width: 100%;
  max-height: 100%;
}

.blankPage strong {
  padding-top: 20px;
  padding-bottom: 10px;
  display: inline-block;
}

html {
  width: 100%;
  height: 100%;
  overflow-y: auto !important;
}

body {
  width: 100%;
  height: 100%;
}

body, p, div, a, button {
  color: #000;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}

.bulletpointlist {
  color: #4c338c;
  list-style: initial;
}

* {
  box-sizing: border-box;
}

.modal {
  justify-content: start !important;
}

.factorSelection_verification_headline {
  margin-top: 20px;
  font-weight: bold;
}

.successPreLogonContainer {
  border-radius: 6px;
  flex-direction: column;
  align-items: start;
  padding: 5px 15px 15px;
  display: flex;
}

.successPreLogonContainer strong {
  color: #000 !important;
}

.successPreLogonContainer p {
  color: var(--text-color) !important;
}

.successPreLogonHeadline, .infoHeadline {
  padding-left: 15px;
}

.success-detached {
  justify-content: center;
  display: flex;
}

.success-detached p {
  text-align: center;
  z-index: 1;
  background: #fff;
  width: 70%;
}

.success-detached .detached-arrow-img {
  width: 100px;
  height: 240px;
  position: absolute;
  top: -5px;
  left: -5px;
}

.success-detached .detached-arrow-icon {
  color: #0000;
  display: inline-block;
  position: relative;
}

.success-detached .detached-arrow-icon:before {
  content: "◄";
  color: #000;
  top: 0;
  left: 0;
}

@keyframes ProgressFlagAnimation {
  from {
    left: 0;
  }

  to {
    left: 350px;
  }
}

.ProgressFlag.Show {
  opacity: 1;
}

.ProgressFlag > div {
  text-align: center;
  -webkit-animation: 1.45s ease-in-out -2s infinite ProgressFlagAnimation;
  width: 2px;
  height: 2px;
  margin: 0 5px;
  animation: 5s ease-in-out -2s infinite ProgressFlagAnimation;
  display: inline-block;
  position: relative;
}

.ProgressFlag > div:first-child {
  -webkit-animation-delay: 0s;
}

.ProgressFlag > div:nth-child(2) {
  -webkit-animation-delay: .2s;
}

.ProgressFlag > div:nth-child(3) {
  -webkit-animation-delay: .4s;
}

@media screen and (width >= 1408px) {
  .container {
    max-width: 906px !important;
  }
}

.listStyleNumber {
  padding-left: 20px;
  list-style-type: lower-number;
}

.device-switch-gif {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
}

@media screen and (width <= 500px) {
  .device-switch-gif {
    width: 100%;
    margin: 0;
  }
}

.device-switch-wrapper .qr-code {
  float: left;
  overflow-wrap: break-word;
  background: #fff;
  padding: 16px;
}

.device-switch-wrapper .device-switch-locked {
  position: fixed;
  inset: 0;
}

.device-switch-wrapper .device-switch-modal {
  z-index: 1000;
  max-width: 600px;
  top: 35%;
}

.device-switch-wrapper .device-switch-modal .device-switch-modal--qr-code {
  margin-bottom: 15px;
  margin-right: 15px;
}

.device-switch-wrapper .device-switch-modal .device-switch-modal--qr-content {
  margin-bottom: 15px;
}

.second-channel-wrapper .second-channel-modal {
  z-index: 1000;
  max-width: 600px;
  box-shadow: none;
}

.second-channel-wrapper .second-channel-pin {
  text-align: center;
  justify-content: center;
}

.otp-to-colleague-or-manager-step-hint h4 {
  margin-bottom: -20px;
}

.otp-to-colleague-or-manager-step-hint p {
  margin-top: 20px;
}

.onboarding_success_button {
  justify-content: end;
  width: 100%;
  margin: 30px 0;
  display: flex;
}

@media only screen and (width <= 568px) {
  .onboarding_success_button button {
    width: 100%;
  }
}

.recovery-initiate-success_buttons {
  place-self: stretch stretch;
}

.fido-registration-success_buttons {
  flex-direction: column;
  gap: 20px;
  width: 300px;
  margin-top: 40px;
  display: flex;
}

@media only screen and (width <= 568px) {
  .fido-registration-success_buttons, .fido-registration-success_buttons button {
    width: 100%;
  }
}

.form-padding {
  padding: 10px 0;
}

.header-svg-pwreset {
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: 450px;
  display: flex;
}

.pw-reset-onboarding-info ol {
  margin: 20px 0;
  list-style: none;
}

.registration {
  margin-bottom: 25px;
}

.registration .device-options_more-options {
  margin-top: 25px !important;
}

.registration .device-options_more-options img:hover, .registration .device-options_more-options h3:hover {
  cursor: pointer;
}

.registration .checkbox-wrapper {
  margin: 15px 0;
}

.details-table-collapsible {
  color: #4c338c;
  border-radius: 10px;
  flex-direction: column;
  max-width: 906px;
  padding: 10px 20px;
  font-family: Lato, sans-serif;
  display: flex;
}

@media screen and (width <= 906px) {
  .details-table-collapsible {
    padding: 10px;
  }
}

.execute_fido_registration {
  margin-bottom: 25px;
}

.execute_fido_registration h2 {
  margin-top: 50px;
}

.execute_fido_registration .device-card-heading {
  margin-top: 25px;
}

.execute_fido_registration .device-card-wrapper {
  margin-top: 20px !important;
}

.execute_fido_registration .fidoRegistration-buttons {
  flex-direction: row;
  justify-content: end;
  gap: 20px;
  margin-top: 25px;
  display: flex;
}

@media screen and (width <= 480px) {
  .execute_fido_registration .fidoRegistration-buttons {
    flex-direction: column-reverse;
  }
}

.execute_fido_registration .registration-information-buttons {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

.userguide-help-modal {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.userguide-help-modal .qr-code {
  align-items: center;
  display: flex;
}

.userguide-help-modal .userguide-help-modal-separator {
  align-items: center;
  width: 100%;
  display: flex;
}

.userguide-help-modal .userguide-help-modal-separator hr {
  border: 1px solid var(--separator-color);
  width: 50%;
  margin: 0;
}

.userguide-help-modal .userguide-help-modal-separator p {
  text-align: center;
  width: 60px;
  margin: 0;
}

.add-another-hint {
  background: #fff;
  border-radius: 6px;
  padding: 10px;
}

.pointer {
  cursor: pointer;
}

.modal .storybook-icon-button {
  flex-direction: row-reverse;
}

.modal .storybook-icon-button img {
  transform: rotate(270deg) !important;
}

.modal .storybook-icon-button img.active {
  transform: none !important;
}

.device-switch-qr-wrapper {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.registration-checkbox-description {
  margin-top: -15px;
  padding-left: 27px;
}

.registration-platformAuth-img {
  width: 100%;
  margin-top: 100px;
}

.registration-platformAuth-img img {
  width: 100%;
}

.registration-platformAuth-errormessage {
  background: #fff;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  display: flex;
}

.registration-platformAuth-buttons {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

.registration-platformAuth-buttons button {
  border: 1px solid #4c338c;
  border-radius: 6px;
  padding: 0 10px;
}

.registration-platformAuth-buttons img {
  margin-left: 5px;
  transform: rotate(0) !important;
}

.device-card-wrapper {
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  display: flex;
}

.p-dont-wrap-username b {
  white-space: nowrap;
}

.padding-top-10 {
  padding-top: 10px;
}

.recovery-user-info {
  border: 3px solid #4c338c;
  padding: 20px;
}

.matching-number {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
}

@media print {
  @page {
    size: 210mm 297mm;
    margin: 5mm 5mm 15mm !important;
  }

  html, body {
    height: 100vh;
    overflow: hidden;
    background-color: #fff !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  html .print-container, body .print-container {
    color: #000 !important;
  }

  html .print-container h1, html .print-container h2, html .print-container h3, html .print-container h4, html .print-container h5, html .print-container h6, body .print-container h1, body .print-container h2, body .print-container h3, body .print-container h4, body .print-container h5, body .print-container h6, html .print-container p, body .print-container p {
    color: #000;
  }

  html .print-container .recovery-user-info, body .print-container .recovery-user-info {
    border: 3px solid #000;
    padding: 20px;
  }
}

#loading {
  background: var(--loading-background-color);
  z-index: 20;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#loading .loading-side {
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 25%;
  display: flex;
  overflow: auto;
}

#loading .loading-side::-webkit-scrollbar {
  display: none;
}

#loading .loading-side .scroll-animated {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#loading .loading-side h1, #loading .loading-side h2, #loading .loading-side h3, #loading .loading-side h4, #loading .loading-side h5, #loading .loading-side h6, #loading .loading-side p, #loading .loading-side a {
  color: #fff;
}

#loading .loading-side img {
  width: 80%;
}

@media screen and (width <= 750px) {
  #loading .loading-side {
    display: none;
  }
}

#loading .loading-middle {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

#loading .loading-text {
  text-align: center;
}

#loading .loading-heading {
  color: #fff;
  text-align: center;
}

#loading .loading-animation-wrapper {
  justify-content: center;
  width: 100%;
  height: 20px;
  margin: 30px 0;
  display: flex;
}

#loading .loading-animation-wrapper .loading-animation {
  background-color: var(--loading-foreground-color);
  color: var(--loading-foreground-color);
  border-radius: 12px;
  width: 20px;
  height: 20px;
  animation: 1s linear .5s infinite alternate loading-animation;
  position: relative;
}

#loading .loading-animation-wrapper .loading-animation:before {
  background-color: var(--loading-foreground-color);
  color: var(--loading-foreground-color);
  content: "";
  border-radius: 12px;
  width: 20px;
  height: 20px;
  animation: 1s infinite alternate loading-animation;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -30px;
}

#loading .loading-animation-wrapper .loading-animation:after {
  background-color: var(--loading-foreground-color);
  color: var(--loading-foreground-color);
  content: "";
  border-radius: 12px;
  width: 20px;
  height: 20px;
  animation: 1s 1s infinite alternate loading-animation;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 30px;
}

@keyframes loading-animation {
  0% {
    background-color: var(--loading-foreground-color);
  }

  50%, 100% {
    background-color: var(--loading-foreground-dark-color);
  }
}

#loading p, #loading li, #loading h1, #loading h2, #loading h3, #loading h4, #loading h5, #loading h6 {
  color: #fff;
  opacity: 1;
  text-align: center;
}

#loading #loading-azure-content {
  overflow-wrap: anywhere;
  flex-direction: column;
  align-items: center;
  width: 30%;
  display: flex;
  overflow: auto;
}

#loading #loading-azure-content button {
  align-self: end;
}

@media screen and (width <= 600px) {
  #loading #loading-azure-content {
    width: 80%;
  }
}

#loading #loading-azure-content .listWrapper {
  overflow: auto;
}

#loading #loading-azure-content .listWrapper ul {
  padding-left: 15px;
  padding-right: 10px;
}

#loading #loading-azure-content .listWrapper ul p {
  text-align: left;
}

#loading #loading-azure-content button {
  margin: 10px 0;
}

#loading #loading-azure-content .valid-entropy-number {
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

#loading #loading-azure-content .azure-validation-number {
  font-size: 35px;
}

#confirm-azure-push-approved {
  color: #6a4bad;
  z-index: 20;
  background: #6a4bade6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#confirm-azure-push-approved button {
  align-self: end;
}

.not-connected {
  color: red;
}

p {
  color: var(--text-color);
  text-align: left;
  margin: 10px 0;
}

p.disabled {
  color: gray;
}

p.secondary {
  color: #000;
}

p.error {
  color: #da3759;
}

p.strong {
  font-weight: bold;
}

p.note {
  font-size: 12px;
  line-height: 160%;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
  margin: 10px 0;
}

h1.secondary, h2.secondary, h3.secondary, h4.secondary, h5.secondary, h6.secondary {
  color: #000;
}

h1.red, h2.red, h3.red, h4.red, h5.red, h6.red {
  color: #da3759;
  text-decoration: underline;
}

.listWrapper {
  color: #4c338c;
  flex-direction: column;
  font-family: Lato, sans-serif;
  display: flex;
}

.storybook-button {
  font-family: var(--button-font-family);
  text-transform: var(--button-text-transform);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  border: 0;
  padding: 10px 22px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.storybook-button.button-lm {
  margin-left: 25px;
}

.storybook-button.button-mt {
  margin-top: 10px;
}

.storybook-button.button-mb {
  margin-bottom: 10px;
}

.storybook-button:disabled {
  cursor: not-allowed;
}

.storybook-button--yellow {
  color: var(--button-label-color);
  background-color: var(--button-background-color);
  border: var(--button-border-width) solid var(--button-border-color);
}

.storybook-button--yellow.storybook-button--border {
  border: var(--button-border-width) solid var(--button-border-accent-color);
}

.storybook-button--yellow:hover {
  color: var(--button-label-hover-color);
  border: var(--button-border-width) solid var(--button-border-hover-color);
  background-color: var(--button-background-hover-color);
}

.storybook-button--yellow:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--yellow:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--purple {
  color: #f2c24e;
  border: var(--button-border-width) solid #4c338c;
  background-color: #4c338c;
}

.storybook-button--purple.storybook-button--border {
  border: var(--button-border-width) solid #f2c24e;
}

.storybook-button--purple:hover {
  color: #eb3c96;
  border: var(--button-border-width) solid #eb3c96;
}

.storybook-button--purple:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #f2c24e;
}

.storybook-button--purple:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--white {
  color: var(--button-outline-label-color);
  background-color: var(--button-outline-background-color);
  border: var(--button-border-width) solid var(--button-outline-border-color);
}

.storybook-button--white.storybook-button--border {
  border: var(--button-border-width) solid var(--button-outline-border-color);
}

.storybook-button--white:hover {
  color: var(--button-outline-label-hover-color);
  border: var(--button-border-width) solid var(--button-outline-border-hover-color);
  background: var(--button-outline-background-hover-color);
}

.storybook-button--white:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--white:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--transparent {
  color: #4c338c;
  background-color: #0000;
}

.storybook-button--link {
  color: var(--button-outline-label-color);
  font-size: var(--button-font-size);
  background-color: #0000;
  padding: 0 !important;
}

.storybook-button--red {
  color: #d10101;
  border: var(--button-border-width) solid #d10101;
}

.storybook-button--red.storybook-button--border {
  border: var(--button-border-width) solid #d10101;
}

.storybook-button--red:hover {
  color: #eb3c96;
  border: var(--button-border-width) solid #eb3c96;
}

.storybook-button--red:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--red:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--success {
  color: var(--button-label-color);
  background-color: var(--success-background-color);
  border: var(--button-border-width) solid var(--success-background-color);
}

.storybook-button--success.storybook-button--border {
  border: var(--button-border-width) solid var(--success-background-color);
}

.storybook-button--success:hover {
  color: var(--button-label-hover-color);
  border: var(--button-border-width) solid var(--success-background-hover-color);
  background-color: var(--success-background-hover-color);
}

.storybook-button--success:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--success:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--error {
  color: var(--button-label-color);
  background-color: var(--error-background-color);
  border: var(--button-border-width) solid var(--error-background-color);
}

.storybook-button--error.storybook-button--border {
  border: var(--button-border-width) solid var(--error-background-color);
}

.storybook-button--error:hover {
  color: var(--button-label-hover-color);
  border: var(--button-border-width) solid var(--error-background-hover-color);
  background-color: var(--error-background-hover-color);
}

.storybook-button--error:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--error:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--warning {
  color: var(--button-label-color-inverted);
  background-color: var(--warning-background-color);
  border: var(--button-border-width) solid var(--warning-background-color);
}

.storybook-button--warning.storybook-button--border {
  border: var(--button-border-width) solid var(--warning-background-color);
}

.storybook-button--warning:hover {
  color: var(--button-label-hover-color-inverted);
  border: var(--button-border-width) solid var(--warning-background-hover-color);
  background-color: var(--warning-background-hover-color);
}

.storybook-button--warning:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--warning:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

@keyframes load {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.loader {
  border: 4px solid #fff3;
  border-left-color: currentColor;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: 1s linear infinite load;
}

.spacing {
  width: 100%;
  display: flex;
}

.spacing.fullWidthChildren > * {
  flex-grow: 1;
}

.modal-wrapper {
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 568px) {
  .modal-wrapper {
    width: 85%;
    min-width: unset;
  }

  .modal-wrapper p {
    font-size: 12px;
  }
}

@media only screen and (width >= 568px) and (width <= 896px) {
  .modal-wrapper {
    width: 50%;
    min-width: unset;
  }
}

.modal-wrapper .modal {
  color: var(--text-color);
  overflow-wrap: anywhere;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  padding: 20px;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 568px) {
  .modal-wrapper .modal {
    padding: 10px;
  }
}

.modal-wrapper .modal .modal-content {
  width: 100%;
  max-height: 55vh;
  padding-right: 10px;
  display: block;
  overflow: auto;
}

.modal-wrapper .modal .modal-buttons {
  justify-content: flex-end;
  gap: 20px;
  margin-top: 15px;
  display: flex;
}

@media only screen and (width <= 1200px) {
  .modal-wrapper .modal .modal-buttons {
    flex-direction: column-reverse;
  }
}

.modal-wrapper .modal .modal-buttons button {
  min-width: 150px;
}

.modal-wrapper .modal .modal-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.modal-wrapper .modal .modal-header .modal-header-button {
  width: 25px;
}

.modal-wrapper .modal .modal-header .modal-icon-heading-wrapper {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 95%;
  display: flex;
}

.modal-wrapper .modal .modal-header .modal-icon-heading-wrapper .modal-header-title {
  width: 100%;
  padding-left: 5px;
  font-size: 16px;
}

@media only screen and (width <= 568px) {
  .modal-wrapper .modal .modal-header .modal-icon-heading-wrapper .modal-header-title {
    width: 75%;
    font-size: 14px;
  }
}

.modal-wrapper .modal .modal-header .modal-icon-heading-wrapper .modal-header-title {
  margin: 0;
}

.modal-wrapper .modal .modal-header .modal-icon, .modal-wrapper .modal .modal-header .modal-close-button {
  width: 20px;
  height: 20px;
}

.modal-wrapper .modal .modal-close-button:hover {
  cursor: pointer;
}

:root {
  --base-line-height: 160%;
  --base-font-size: 16px;
  --background-color: #fff;
  --box-shadow: 0 0 1px 0 #0f1a2e26, 0 1px 4px 0 #0f1a2e26;
  --border-radius: 8px;
  --text-color: #0f1a2e;
  --text-color-muted: #5e5e72;
  --font-family: "Lato", sans-serif;
  --input-border-color: #e1e1ea;
  --input-label-color: #778;
  --input-label-font-size: 14px;
  --input-border-width: 2px;
  --input-border-radius: 4px;
  --input-height: 40px;
  --footer-background-color: #0a3b61;
  --footer-link-color: #2dbecd;
  --footer-text-color: white;
  --footer-spacing: 40px 0;
  --header-text-color: white;
  --menu-background-color: #0f69af;
  --menu-background-active-color: #0a3b61;
  --menu-border-color: #0a3b61;
  --menu-boxshadow-color: none;
  --menu-close-icon-color: #2dbecd;
  --menu-link-color: #2dbecd;
  --menu-text-color: white;
  --menu-item-background-color: transparent;
  --card-border: 1px solid #b4b4c1;
  --button-border-radius: 4px;
  --button-font-family: "Lato", sans-serif;
  --button-font-size: 14px;
  --button-label-color: #fff;
  --button-label-color-inverted: #0f1a2e;
  --button-label-hover-color: #fff;
  --button-background-color: #0f69af;
  --button-background-hover-color: #085897;
  --button-border-color: #0f69af;
  --button-border-hover-color: #085897;
  --button-border-accent-color: #0f69af;
  --button-border-width: 2px;
  --button-outline-label-color: #0f69af;
  --button-outline-background-color: white;
  --button-outline-border-color: #0f69af;
  --button-outline-label-hover-color: #085897;
  --button-outline-border-hover-color: #0f69af;
  --button-outline-background-hover-color: #0f69af1a;
  --button-text-transform: none;
  --snackbar-background-color: #ffc832;
  --snackbar-border-radius: 4px;
  --expander-font-family: "Lato", sans-serif;
  --link-color: #0f69af;
  --separator-color: #b4b4c1;
  --checkbox-color: #0f69af;
  --error-background-color: #e61e50;
  --error-background-hover-color: #c81c47;
  --warning-background-color: #ffc832;
  --warning-background-hover-color: #deaf2f;
  --success-background-color: #01884c;
  --success-background-hover-color: #016b3c;
  --info-background-color: #0f69af;
  --error-link-color: #fff;
  --warning-link-color: #0f1a2e;
  --success-link-color: #fff;
  --info-link-color: #fff;
  --loading-background-color: #0a3b6180;
  --loading-foreground-dark-color: #ffffff80;
  --digit-background-color: #f8f8fc;
  --digit-border-default: 2px solid #e1e1ea;
  --digit-border-focus: 2px solid #0f69af;
  --digit-border-radius: 4px;
  --digit-width: 40px;
  --digit-height: 40px;
  --infobox-background-error-color: #fff6f8;
  --infobox-background-warning-color: #fefbf5;
  --infobox-background-info-color: #f4faff;
  --infobox-background-success-color: #f4fbf8;
  --infobox-text-error-color: #e61e50;
  --infobox-text-warning-color: #ffc832;
  --infobox-text-info-color: #0f69af;
  --infobox-text-success-color: #01884c;
  --infobox-border-error: 1px solid #e61e50;
  --infobox-border-warning: 1px solid #ffc832;
  --infobox-border-info: 1px solid #0f69af;
  --infobox-border-success: 1px solid #01884c;
  --infobox-padding: 16px;
  --infobox-box-shadow: 0 0 1px 0 #0f1a2e26, 0 1px 4px 0 #0f1a2e26;
  --infobox-gap: 0;
  --card-group-header-bakground-success-color: #01884c26;
  --card-group-header-bakground-warning-color: #ffc83240;
  --card-group-header-bakground-info-color: #d8eaf8;
  --card-group-background-color-parent: #f8f8fc;
}

.theme_colored {
  --base-line-height: 160%;
  --base-font-size: 16px;
  --background-color: #96d7d2;
  --box-shadow: 0 0 1px 0 #0f1a2e26, 0 1px 4px 0 #0f1a2e26;
  --text-color: #4c338c;
  --input-label-color: black;
  --input-label-font-size: 12px;
  --font-family: "Verdana";
  --input-border-color: transparent;
  --input-border-width: 0;
  --input-border-radius: 5px;
  --input-height: 30px;
  --footer-background-color: #96d7d2;
  --footer-link-color: #4c338c;
  --footer-text-color: #4c338c;
  --footer-spacing: 0 0 20px;
  --menu-background-color: white;
  --menu-border-color: #4c338c;
  --menu-boxshadow-color: 0px 0px 5px 0px #4c338c;
  --menu-close-icon-color: #da3759;
  --menu-link-color: black;
  --menu-text-color: black;
  --menu-item-background-color: #f3f3f3;
  --card-border: none;
  --button-border-radius: 6px;
  --button-font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --button-font-size: 16px;
  --button-label-color: #4c338c;
  --button-label-color-inverted: black;
  --button-label-hover-color: #eb3c96;
  --button-background-color: #f2c24e;
  --button-background-hover-color: #f2c24e;
  --button-border-color: #f2c24e;
  --button-border-hover-color: #eb3c96;
  --button-border-accent-color: #4c338c;
  --button-border-width: 1px;
  --button-outline-label-color: #4c338c;
  --button-outline-background-color: white;
  --button-outline-border-color: #4c338c;
  --button-outline-label-hover-color: #eb3c96;
  --button-outline-border-hover-color: #eb3c96;
  --button-outline-background-hover-color: #fff;
  --button-text-transform: uppercase;
  --snackbar-background-color: #ffc832;
  --snackbar-border-radius: 4px;
  --expander-font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --link-color: #4c338c;
  --separator-color: #4c338c;
  --checkbox-color: #eb3c96;
  --error-background-color: #da3759;
  --warning-background-color: #f2c24e;
  --success-background-color: #b3d362;
  --info-background-color: #4c338c;
  --error-link-color: #fff;
  --warning-link-color: #000;
  --success-link-color: #000;
  --info-link-color: #fff;
  --loading-background-color: #6a4bade6;
  --loading-foreground-color: #96d7d2;
  --loading-foreground-cark-color: #96d7d280;
  --digit-background-color: #f8f8fc;
  --digit-border-default: 2px solid #e1e1ea;
  --digit-border-focus: 2px solid #4c338c;
  --digit-border-radius: 4px;
  --digit-width: 40px;
  --digit-height: 40px;
  --infobox-background-error-color: #fff;
  --infobox-background-warning-color: #fff;
  --infobox-background-info-color: #fff;
  --infobox-background-success-color: #fff;
  --infobox-text-error-color: #4c338c;
  --infobox-text-warning-color: #4c338c;
  --infobox-text-info-color: #4c338c;
  --infobox-text-success-color: #4c338c;
  --infobox-border-error: none;
  --infobox-border-warning: none;
  --infobox-border-info: none;
  --infobox-border-success: none;
  --infobox-padding: 20px;
  --infobox-box-shadow: 0 0 20px 0 #a6a8a7;
  --infobox-gap: 10px;
  --card-group-header-bakground-success-color: #01884c26;
  --card-group-header-bakground-warning-color: #ffc83240;
  --card-group-header-bakground-info-color: #d8eaf8;
}

html, body, header, main {
  background-color: var(--background-color);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
}

@font-face {
  font-family: Merck-Regular;
  src: url("MerckWeb-Regular.15f01ff3.woff") format("woff");
}

div[class="Merck-Regular"] * {
  font-family: Merck-Regular;
}

body, html {
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-top: 1px solid var(--separator-color);
  width: 100%;
}

.muted {
  color: var(--text-color-muted);
}

* {
  box-sizing: border-box;
  font-family: var(--font-family);
}

.cs-main-container {
  flex-direction: column;
  flex: 1;
  justify-content: start;
  align-self: center;
  width: 100%;
  max-width: 906px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
  display: flex;
}

.cs-main-container-width {
  align-self: center;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.userguide-main-container {
  width: 100%;
  margin-bottom: 40px;
}

.main-container-content {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  max-width: 906px;
  padding: 0 10px;
  display: flex;
}

.mainColor {
  color: var(--text-color);
}

.center-svg {
  width: 100%;
  max-width: 450px;
  height: 100%;
  min-height: 300px;
  max-height: 500px;
}

.center-svg.cp {
  max-width: 350px;
}

.header-svg {
  flex-direction: column;
  align-content: center;
  align-items: center;
  display: flex;
}

.modaldetails {
  background-color: #c5bbdc61;
  border-left: 5px solid #4c338d;
  border-radius: 6px;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  padding: 5px 10px;
}

.modaldetails .headingwrapper {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  display: flex;
}

.modaldetails .headingwrapper div {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  display: flex;
}

.modaldetails .headingwrapper h3, .modaldetails .headingwrapper h4, .modaldetails .headingwrapper h5 {
  text-align: left;
  margin: 0;
}

.modaldetails .headingwrapper .heading {
  cursor: pointer;
}

.modaldetails .hidden {
  display: none !important;
}

.modaldetails .textwrapper {
  alignment: center;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  width: 100%;
  padding: 15px;
}

.modaldetails .modaldetails-header-icon {
  margin-right: 5px;
}

.modaldetails .modal-details_copy-button {
  justify-content: center;
  margin-top: 10px;
  display: flex;
}

footer {
  background: var(--footer-background-color);
  min-height: 5vh;
  margin-top: auto;
}

@media screen and (width <= 480px) {
  footer {
    clear: left;
    font-size: 12px;
  }
}

@media screen and (width <= 919px) {
  footer #copyright {
    width: 100%;
  }
}

@media screen and (width >= 920px) {
  footer hr {
    display: none;
  }
}

footer a, footer span {
  color: var(--footer-link-color) !important;
}

footer .main-container-footer {
  text-align: center;
  margin: var(--footer-spacing);
}

footer #versionNumber {
  width: 100%;
  color: var(--footer-text-color);
  justify-content: center;
  margin-top: 5px;
  font-size: 12px;
  display: flex;
}

footer .content-container-footer {
  flex-flow: wrap;
  place-content: start center;
  display: flex;
}

footer .footer-item {
  white-space: nowrap;
  flex: none;
  padding: 0 10px;
}

footer .footer-item a {
  text-decoration: underline;
}

footer .pb-0 {
  padding-bottom: 0 !important;
}

footer hr {
  border: .5px solid var(--separator-color);
  margin: 12px 0;
}

footer #copyright {
  white-space: unset;
  margin-top: 10px;
}

footer #copyright p {
  word-break: break-word;
  text-align: center;
  color: var(--footer-text-color);
}

footer #love {
  white-space: unset;
  cursor: default;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  display: flex;
  position: relative;
}

footer #love p {
  word-break: break-word;
  text-align: center;
  color: var(--footer-text-color);
  margin: 5px 0 0;
}

footer #love #footer-emoji {
  margin: 0 5px;
  padding-top: 4px;
}

footer #love #footer-emoji:hover {
  animation: 2s infinite pulse;
  transform: scale(2);
}

@keyframes pulse {
  0% {
    transform: scale(.95);
  }

  70% {
    transform: scale(1.5);
  }

  75% {
    transform: scale(1.6);
  }

  100% {
    transform: scale(.95);
  }
}

.link {
  color: var(--link-color);
  cursor: pointer;
  margin: 10px 0;
  text-decoration: underline;
}

.link.secondary {
  color: #000;
}

.link.gray {
  color: gray;
}

.link.purple {
  color: #4c338c !important;
}

.link:not(.underline) {
  text-decoration: none;
}

.link.strong {
  font-weight: bold;
}

.progressbarWrapper {
  font-family: var(--font-family);
  color: #4c338c;
  background-color: var(--background-color);
  flex-direction: column;
  max-width: 906px;
  display: flex;
}

.progressbarWrapper .progressbarHeader {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.progressbarWrapper .progressbarHeader h3 {
  margin: 0;
  font-weight: bold;
}

@media screen and (width <= 600px) {
  .progressbarWrapper .progressbarHeader {
    flex-direction: column;
  }

  .progressbarWrapper .progressbarHeader .progressbarHeading {
    text-align: center;
    width: 100%;
    margin-top: 40px;
    font-size: 12px;
  }
}

.progressbarWrapper .progressbarStepContainer {
  justify-content: space-between;
  display: flex;
}

@media screen and (width <= 600px) {
  .progressbarWrapper .progressbarStepContainer {
    flex-direction: column;
    margin-top: 20px;
  }
}

.progressbarWrapper .progressbarStep {
  width: 30%;
}

@media screen and (width <= 600px) {
  .progressbarWrapper .progressbarStep {
    width: 100%;
  }
}

.progressbarStepWrapper {
  font-family: var(--font-family);
  color: #4c338c;
  background-color: var(--background-color);
  flex-direction: column;
  align-items: center;
  max-width: 285px;
  height: 150px;
  display: flex;
}

@media screen and (width <= 600px) {
  .progressbarStepWrapper {
    max-width: none;
    height: 45px;
  }
}

.progressbarStepImage {
  align-items: flex-end;
  height: 110px;
  display: flex;
}

@media screen and (width <= 600px) {
  .progressbarStepImage {
    display: none;
  }
}

.progressbarStepInformation {
  background-color: #f2c24e;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  display: flex;
}

.progressbarStepInformation.active {
  background-color: #4c338c;
}

.progressbarStepInformation.active p {
  color: #f2c24e !important;
}

.progressbarStepInformation p {
  font-size: 12px;
}

.progressbarStepInformation .checkmark {
  height: 12px;
}

.progressbarStepImage img {
  height: 110px;
}

.stepCircle {
  vertical-align: top;
  width: 26px;
  height: 26px;
  display: inline-block;
  position: relative;
}

.stepCircle:before {
  content: "";
  z-index: 2;
  background: #4c338c;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
}

.stepCircle:after {
  content: "";
  border-radius: 50%;
  width: 15.6px;
  height: 2px;
  margin: 28.6px auto;
  display: block;
}

.stepCircle .slice {
  clip: rect(0px, 26px, 26px, 13px);
  width: 26px;
  height: 26px;
  animation: 1s bake-pie;
  position: absolute;
}

.stepCircle .slice span {
  clip: rect(0px, 26px, 26px, 13px);
  background-color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.stepCircle .activeSlice span {
  background-color: #f2c24e;
}

.stepCircle .spacer span {
  background-color: #4c338c;
}

.stepCircle .spacer span.transparent {
  background-color: #6a4bad;
}

.transparent:before {
  background: #6a4bad !important;
}

nav {
  background-color: var(--menu-background-color);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 16px;
  display: flex;
  position: relative;
}

nav .navigation-header-button {
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: flex;
}

nav.active {
  background-color: var(--menu-background-active-color);
}

nav.active .navigation-wrapper {
  background-color: var(--menu-background-active-color);
  z-index: 1001;
  box-shadow: 0px 0px 5px 0px var(--menu-boxshadow-color);
  border-radius: 0;
  width: 100%;
  max-width: 320px;
  position: absolute;
  top: 0;
  right: 0;
}

nav.active .navigation-welcome {
  padding: 24px 32px 0;
}

nav.active .navigation-welcome .name {
  color: var(--menu-text-color);
  margin: 0;
  font-size: 18px;
  line-height: 140%;
}

nav.active .navigation-welcome .mail {
  color: var(--menu-text-color);
  margin: 4px 0 0;
  font-size: 14px;
  line-height: 140%;
}

nav.active .navigation-header {
  width: 100%;
}

nav.active h4 {
  color: var(--menu-text-color);
}

nav.active .storybook-button {
  margin-top: 40px;
}

nav .navigation-title {
  color: var(--header-text-color);
}

nav p, nav h1, nav h2, nav h3, nav h4, nav h5, nav h6, nav img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

nav .navigation-menu-heading {
  cursor: pointer;
  padding-right: 10px;
}

nav .navigation-menu-heading h4 {
  color: var(--menu-text-color);
}

nav .navigation-header {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

nav .navigation-header p {
  color: var(--menu-text-color);
  margin: 0;
}

nav .navigation-header p:first-child {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
}

nav .navigation-header p:nth-child(2) {
  color: var(--menu-text-color);
  font-size: 8px;
}

nav .navigation-header h3, nav .navigation-header h4 {
  margin: 0;
}

nav .navigation-header .toggle-container {
  cursor: pointer;
  fill: #fff;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: inline-block;
}

nav .navigation-header .toggle-container path {
  stroke: #fff;
}

nav .navigation-content {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 40px 32px;
  display: flex;
}

nav .navigation-content .navigation-entry {
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  gap: 8px;
  width: 100%;
  line-height: 32px;
  display: flex;
}

nav .navigation-content .navigation-entry svg {
  color: var(--menu-link-color);
}

nav .navigation-content .navigation-entry p {
  color: var(--menu-link-color);
  font-size: 14px;
}

nav .navigation-content .navigation-entry p, nav .navigation-content .navigation-entry h5 {
  margin: 0;
}

nav .navigation-content .navigation-entry:hover {
  background: var(--menu-item-background-color);
}

nav .navigation-content .navigation-entry .navigation-entry-note {
  flex-direction: column;
  display: flex;
}

nav .navigation-content .navigation-entry .navigation-entry-note p:nth-child(2) {
  color: var(--menu-link-color);
  font-size: 8px;
}

nav .navigation-content .navigation-entry .toggle-wrapper {
  align-self: start;
  height: 34px;
  transform: scale(.75);
}

nav .navigation-content .navigation-entry .toggle-wrapper .toggle {
  height: 34px;
}

nav .navigation-content .navigation-entry .toggle-wrapper .toggle .toggle-item {
  margin: 0;
}

.icon.icon--colorize path, .icon.icon--colorize circle {
  stroke: currentColor;
  fill: none;
}

.toggle-wrapper .disabled label.toggle-item {
  background: #edeeea;
}

.toggle-wrapper .disabled .check {
  background: #a6a8a7;
}

.toggle-wrapper .disabled label.toggle-item:before {
  border-color: #a6a8a7;
}

.toggle-wrapper .disabled .toggle-input:checked + label .check {
  background: #a6a8a7;
}

.toggle-wrapper .toggle {
  display: inline-block;
  position: relative;
}

.toggle-wrapper label.toggle-item {
  transform-origin: 20%;
  cursor: pointer;
  background: #edeeea;
  border: 1px solid #a6a8a7;
  border-radius: 50px;
  width: 60px;
  height: 31px;
  margin: 10px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.toggle-wrapper label.toggle-item:before {
  border: 2px solid #c34a4a;
  border-radius: 32px;
  width: 20px;
  height: 20px;
  transition: all .3s;
  display: block;
  top: 5px;
  left: 5px;
}

.toggle-wrapper input {
  opacity: 0;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.toggle-wrapper .toggle-input:checked + label .check {
  background: #8bc34a;
  left: 30.7px;
  transform: rotate(360deg);
}

.toggle-wrapper .check {
  background: #c34a4a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: all .4s;
  position: absolute;
  top: 2.3px;
  left: 2.3px;
}

.buttonGroup {
  flex-flow: wrap;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 30px;
  display: flex;
}

.buttonGroup.pulledRight {
  float: right;
}

@media screen and (width <= 480px) {
  .buttonGroup {
    flex-direction: column-reverse;
  }

  .buttonGroup.pulledRight {
    float: unset !important;
  }

  .buttonGroup.pulledRight button {
    width: 100%;
  }
}

.textInput {
  flex-direction: column;
  display: flex;
}

.textInput .textInput_label {
  color: var(--input-label-color);
  font-size: var(--input-label-font-size);
  margin-bottom: 10px;
  line-height: 120%;
}

.textInput .textInput_input {
  border: var(--input-border-width) solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  height: var(--input-height);
  padding: 8px;
}

.textInput .textInput_input:focus {
  outline-width: 0;
}

.textInput .textInput-notifications {
  gap: 10px;
  display: flex;
}

.textInput .textInput-notifications .textInput_message {
  align-self: flex-start;
  margin-top: 10px;
}

.textInput .textInput-notifications .textInput_message .textInput_text {
  border-radius: 5px;
  min-width: 48px;
  margin: 0;
  padding: 8px;
  font-size: 12px;
}

.textInput .textInput-notifications .textInput_message .textInput_text.error {
  background-color: var(--error-background-color);
  color: var(--error-link-color);
}

.textInput .textInput-notifications .textInput_message .textInput_text.warning {
  background-color: var(--warning-background-color);
  color: var(--warning-link-color);
}

.textInput .textInput-notifications .textInput_message .textInput_corner {
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  transform: translate(16px, -7px);
}

.textInput .textInput-notifications .textInput_message .textInput_corner.error {
  border-bottom: 7px solid var(--error-background-color);
}

.textInput .textInput-notifications .textInput_message .textInput_corner.warning {
  border-bottom: 7px solid var(--warning-background-color);
}

.textInput .input-password-wrapper {
  position: relative;
}

.textInput .input-password-wrapper input {
  width: 100%;
}

.textInput .input-password-wrapper input.icon {
  padding-left: 30px;
}

.textInput .input-password-wrapper .img-left {
  margin: 3px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.textInput .input-password-wrapper .img-right {
  cursor: pointer;
  margin: 3px;
  position: absolute;
  top: 4px;
  right: 5px;
}

.qr-code-reader--wrapper {
  background: #fff;
  border-radius: 6px;
  width: 100%;
  max-width: 500px;
  padding: 5px 10px 10px;
}

.qr-code-reader--wrapper .qr-code-reader-header {
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 5px;
  display: flex;
}

.qr-code-reader--wrapper .qr-code-reader-header h2 {
  margin: 0;
}

.qr-code-reader--wrapper .qr-code-reader--buttons {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.qr-code-reader--wrapper .qr-code-reader--buttons button {
  margin-top: 10px;
}

@media screen and (width <= 480px) {
  .qr-code-reader--wrapper .qr-code-reader--buttons button {
    width: 100%;
  }
}

.qr-code-reader--wrapper .qr-code-reader {
  position: relative;
}

.qr-code-reader--wrapper .qr-code-reader section > div {
  border-radius: 6px;
}

.qr-code-reader--wrapper .qr-code-container {
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.qr-code-reader--wrapper .qr-code-video {
  transform: unset;
}

@media screen and (width >= 351px) and (width <= 400px) {
  .qr-code-reader--wrapper .qr-code-video {
    transform: scale(.8);
  }
}

@media screen and (width >= 326px) and (width <= 350px) {
  .qr-code-reader--wrapper .qr-code-video {
    transform: scale(.7);
  }
}

@media screen and (width <= 325px) {
  .qr-code-reader--wrapper .qr-code-video {
    transform: scale(.6);
  }
}

.geoLocationModalContent {
  padding-bottom: 20px;
}

.geoLocationModalContent .information {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.geoLocationModalContent .information p {
  text-align: unset;
}

.geoLocationModalContent .geoLocationInfo {
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  display: grid;
}

.userguideAppWrapper {
  justify-content: center;
  display: flex;
}

.userguideAppWrapper .userguideApp {
  width: 85%;
}

@media screen and (width <= 500px) {
  .userguideAppWrapper .userguideApp {
    width: 95%;
  }
}

.userguideAppWrapper .userguideApp #userguide-header-id {
  margin: 40px 0 60px;
}

@media screen and (width <= 500px) {
  .userguideAppWrapper .userguideApp #userguide-header-id {
    width: 70%;
    margin: 20px 0 40px;
  }
}

.userguideAppWrapper .userguideApp .userguide-version-control {
  flex-direction: column;
  gap: 70px;
  margin-top: 50px;
  margin-bottom: 110px;
  display: flex;
}

@media only screen and (width >= 777px) {
  .userguideAppWrapper .userguideApp .userguide-version-control {
    flex-direction: row;
    gap: 20px;
    display: flex;
  }
}

.userguide-content-entry {
  cursor: pointer;
  background-color: #fff;
  border-left: 5px solid #4c338c;
  border-radius: 6px;
}

.userguide-content-entry h1, .userguide-content-entry h2, .userguide-content-entry h3, .userguide-content-entry h4, .userguide-content-entry h5, .userguide-content-entry h6, .userguide-content-entry p, .userguide-content-entry a {
  color: #4c338c;
}

.userguide-content-entry a {
  word-break: break-all;
}

.userguide-content-entry .userguide-header-button {
  flex-direction: row;
  align-items: center;
  width: 95%;
  padding-left: 10px;
  display: flex;
}

.userguide-content-entry .userguide-header-button img {
  transform: rotate(-90deg);
}

.userguide-content-entry .userguide-header-button img.active {
  transform: none;
}

.userguide-content-entry hr {
  border: none;
  border-bottom: 2px solid var(--separator-color);
  width: 98%;
  margin: 3px 0;
  margin-left: 1% !important;
}

.userguide-content-entry hr.hidden {
  display: none;
}

.userguide-content-entry .userguide-card-container {
  flex-flow: wrap;
  flex: 1 0 calc(32% - 10px);
  padding-bottom: 15px;
  display: flex;
}

.userguide-content-entry .userguide-card-container.hidden {
  display: none;
}

.userguide-content-entry .userguide-card-container .userguide-card {
  flex-direction: column;
  align-items: flex-start;
  width: 33.3333%;
  padding: 0 20px;
  display: flex;
}

@media screen and (width <= 670px) {
  .userguide-content-entry .userguide-card-container .userguide-card {
    width: 100%;
    height: unset;
  }
}

@media screen and (width <= 970px) and (width >= 670px) {
  .userguide-content-entry .userguide-card-container .userguide-card {
    width: 50%;
  }
}

.userguide-content-entry .userguide-card-container .userguide-card .userguide-card-img-wrapper {
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  display: flex;
}

.userguide-content-entry .userguide-card-container .userguide-card .userguide-card-img-wrapper .userguide-card-img {
  object-fit: cover;
  max-width: 96%;
  max-height: 240px;
  overflow: hidden;
}

.userguide-card-pretext {
  cursor: default;
  flex-direction: column;
  align-items: flex-start;
  width: 33.3333%;
  padding: 0 20px;
  display: flex;
}

@media screen and (width <= 670px) {
  .userguide-card-pretext {
    height: unset;
    width: 100%;
  }
}

@media screen and (width <= 970px) and (width >= 670px) {
  .userguide-card-pretext {
    width: 50%;
  }
}

.storybook-icon-button {
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.storybook-icon-button h3 {
  color: var(--text-color);
}

.storybook-icon-button h2 {
  margin: 0;
  font-size: 24px;
}

@media screen and (width <= 600px) {
  .storybook-icon-button h2 {
    font-size: 18px;
  }
}

.storybook-icon-button img {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}

.storybook-icon-button img.active {
  transform: none;
}

.locked {
  z-index: 1000;
  position: fixed;
  inset: 0;
  background-color: #00000080 !important;
}

.ug-image-modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (width <= 896px) {
  .ug-image-modal-wrapper {
    width: 90%;
  }
}

.ug-image-modal-wrapper .ug-image-modal {
  color: #4c338c;
  overflow-wrap: anywhere;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  gap: 5px;
  max-height: 90vh;
  padding: 20px;
  display: flex;
  overflow-y: auto;
  box-shadow: 0 0 20px #a6a8a7;
}

.ug-image-modal-wrapper .ug-image-modal .ug-image-modal-header {
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 5px;
  display: flex;
}

.ug-image-modal-wrapper .ug-image-modal .ug-image-modal-header .nowrap-paragraph {
  white-space: nowrap;
}

.ug-image-modal-wrapper .ug-image-modal .ug-image-modal-header p {
  margin-top: 0;
}

.ug-image-modal-wrapper .ug-image-modal .ug-image-modal-header a {
  word-break: break-all;
}

.ug-image-modal-wrapper .ug-image-modal .ug-image-modal-content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.ug-image-modal-wrapper .ug-image-modal .ug-image-modal-content img {
  max-width: 100%;
}

@media screen and (height <= 700px) {
  .ug-image-modal-wrapper .ug-image-modal .ug-image-modal-content img {
    max-height: 50vh !important;
  }
}

@media screen and (height <= 900px) {
  .ug-image-modal-wrapper .ug-image-modal .ug-image-modal-content img {
    max-height: 65vh;
  }
}

.ug-image-modal-wrapper .ug-image-modal .ug-image-modal-footer {
  height: 10%;
}

.userguide-fullscreen-img-modal--icon, .userguide-fullscreen-img-modal--close-button {
  width: 20px;
  height: 20px;
}

.userguide-fullscreen-img-modal--close-button:hover {
  cursor: pointer;
}

.userguide-fullscreen-img-modal--buttons {
  justify-content: space-between;
  margin-top: 10px;
  display: flex;
}

.userguide-fullscreen-img-modal--buttons.firstImg {
  justify-content: flex-end;
}

.userguide-settings-entry {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.userguide-settings-entry .further-options-info {
  height: 25px;
}

.userguide-settings-entry .dropdown-heading {
  text-align: center;
  margin: 20px 0;
}

.userguide-settings-entry .dropdown-button {
  color: #4c338c;
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 45px;
  padding: 5px 5px 5px 10px;
  font-weight: bold;
  display: flex;
}

.userguide-settings-entry .dropdown-button h3 {
  margin: 0;
  font-size: 16px;
}

.userguide-settings-entry .dropdown-button.arrow-no-rot img {
  transform: rotate(0);
}

.userguide-settings-entry .dropdown-button.hide-label {
  color: #0000;
}

.userguide-settings-entry .dropdown-button .arrow-icon {
  width: 35px;
  transform: rotate(90deg);
}

.userguide-settings-entry .dropdown-button.active {
  border-radius: 6px;
}

.userguide-settings-entry .dropdown-button.active img {
  transform: rotate(0);
}

.userguide-settings-entry .dropdown-button.selectedValue {
  color: #f2c24e;
}

.userguide-settings-entry .dropdown-button.disabled {
  opacity: .7;
}

.userguide-settings-entry .dropdown-button.disabled img {
  visibility: hidden;
}

.userguide-settings-entry .dropdown-button .device-icon {
  margin-right: 5px;
}

.userguide-settings-entry .entry-dropdown ::-webkit-scrollbar {
  width: 10px;
}

.userguide-settings-entry .entry-dropdown ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 0 6px;
}

.userguide-settings-entry .entry-dropdown ::-webkit-scrollbar-thumb {
  background: #4c338c;
  border-radius: 5px;
}

.userguide-settings-entry .entry-dropdown ::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}

.userguide-settings-entry .entry-dropdown .dropdown-content {
  border-radius: 6px;
  width: 100%;
  max-width: 300px;
  max-height: 202px;
  margin-top: -20px;
  position: absolute;
  overflow-y: auto;
  transform: translate(-50%);
  box-shadow: 5px 10px 45px #5a5a5a;
}

.userguide-settings-entry .entry-dropdown .dropdown-content .dropdown-item {
  color: #4c338c;
  cursor: pointer;
  background: #fff;
  border-top: 1px solid #edeeea;
  padding: 10px;
  transition: all .2s;
}

.userguide-settings-entry .entry-dropdown .dropdown-content .dropdown-item:hover {
  background: #eee;
}

.userguide-settings-entry .entry-dropdown .dropdown-content .dropdown-item.dropdown-item-last {
  border-radius: 0;
}

.pwdGenerateModalContent {
  padding-bottom: 20px;
}

.pwdGenerateModalContent .information {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.pwdGenerateModalContent .information p {
  text-align: unset;
}

.pwdGenerateModalContent .pwdInfo {
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 20px;
  display: grid;
}

.pwdGenerateModalContent .pwdInfo .iconWrapper {
  transform: unset;
}

.pwdGenerateModalContent .pwdInfo .iconWrapper :active {
  opacity: .3;
}

.pwdGenerateModalContent .pwdInfo .icon-size {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

@media screen and (width <= 1150px) {
  .pwdGenerateModalContent .pwdInfo .icon-size {
    width: 20px;
    height: 20px;
  }
}

.storybook-box {
  padding: var(--infobox-padding);
  box-shadow: var(--infobox-box-shadow);
  overflow-wrap: anywhere;
  border-radius: 6px;
  flex-direction: column;
  gap: 5px;
  display: flex;
  overflow: hidden;
}

.storybook-box .storybook-headline {
  line-height: 140%;
  margin: 0 !important;
}

.storybook-box .storybook-subtitle {
  line-height: 160%;
  margin-top: 4px !important;
  margin-bottom: 0 !important;
}

.storybook-box svg {
  flex-shrink: 0;
}

.storybook-info-box {
  background-color: var(--infobox-background-info-color);
  border: var(--infobox-border-info);
}

.storybook-info-box .storybook-headline {
  color: var(--infobox-text-info-color);
}

.storybook-error-box {
  background-color: var(--infobox-background-error-color);
  border: var(--infobox-border-error);
}

.storybook-error-box .storybook-headline {
  color: var(--infobox-text-error-color);
}

.storybook-warning-box {
  background-color: var(--infobox-background-warning-color);
  border: var(--infobox-border-warning);
}

.storybook-warning-box .storybook-headline {
  color: var(--infobox-text-warning-color);
}

.storybook-success-box {
  background-color: var(--infobox-background-success-color);
  border: var(--infobox-border-success);
}

.storybook-success-box .storybook-headline {
  color: var(--infobox-text-success-color);
}

.storybook-info-box--content {
  align-items: flex-start;
  gap: var(--infobox-gap);
  display: flex;
}

.storybook-info-box--icon {
  width: 20px;
  height: 20px;
}

.storybook-close {
  width: 20px;
  height: 20px;
  margin-left: auto;
}

.storybook-close-error {
  color: var(--infobox-text-error-color);
}

.storybook-close-info {
  color: var(--infobox-text-info-color);
}

.storybook-close-warning {
  color: var(--infobox-text-warning-color);
}

.storybook-close-success {
  color: var(--infobox-text-success-color);
}

.storybook-info-box--close-button:hover {
  cursor: pointer;
}

.storybook-info-box--buttons {
  justify-content: flex-end;
  display: flex;
}

.dropdown {
  width: 100%;
}

.dropdown.absolut .dropdown-button {
  width: 200px;
}

.dropdown.absolut .dropdown-content {
  width: 200px;
  position: absolute;
}

.dropdown ::-webkit-scrollbar {
  width: 10px;
}

.dropdown ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 0 6px;
}

.dropdown ::-webkit-scrollbar-thumb {
  background: #4c338c;
  border-radius: 5px;
}

.dropdown ::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9;
}

.dropdown .dropdown-button {
  color: #4c338c;
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 15px;
  font-weight: bold;
  display: flex;
}

.dropdown .dropdown-button.arrow-no-rot img {
  transform: rotate(0);
}

.dropdown .dropdown-button.hide-label {
  color: #0000;
}

.dropdown .dropdown-button img {
  width: 35px;
  transform: rotate(90deg);
}

.dropdown .dropdown-button.active {
  border-radius: 6px 6px 0 0;
}

.dropdown .dropdown-button.active img {
  transform: rotate(0);
}

.dropdown .dropdown-button.selectedValue {
  color: #f2c24e;
}

.dropdown .dropdown-button.disabled {
  opacity: .7;
}

.dropdown .dropdown-button.disabled img {
  visibility: hidden;
}

.dropdown .dropdown-content {
  border-radius: 0 0 6px 6px;
  max-height: 202px;
  overflow-y: auto;
}

.dropdown .dropdown-content .dropdown-item {
  color: #4c338c;
  cursor: pointer;
  background: #fff;
  border-top: 1px solid #edeeea;
  padding: 10px;
  transition: all .2s;
}

.dropdown .dropdown-content .dropdown-item:hover {
  background: #f4f4f4;
}

.dropdown .dropdown-content .dropdown-item.dropdown-item-last {
  border-radius: 0;
}

.radiobutton-group {
  background: var(--background-color);
  margin-top: 25px;
}

.radiobutton-group h3 {
  color: var(--text-color);
}

.radiobutton-group .hide {
  display: none !important;
}

.radiobutton-group hr {
  border: .5px solid var(--separator-color);
}

.radiobutton-group.no-background {
  background: none !important;
}

.radiobutton-wrapper {
  background: var(--background-color);
  z-index: 1;
  flex-direction: column;
  align-items: start;
  max-width: 906px;
  margin: 20px 0;
  display: flex;
}

.radiobutton-wrapper.highlight {
  border: 2px solid #f2c24e;
  padding: 5px;
}

.radiobutton-wrapper.no-highlight {
  padding-left: 7px;
}

.radiobutton-wrapper .disabled {
  color: gray;
}

.radiobutton-wrapper .font-weight-normal {
  font-weight: normal;
}

.radiobutton-wrapper .recommended-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -17px;
  display: flex;
}

.radiobutton-wrapper .recommended-container .recommended {
  color: var(--text-color);
  background: #f2c24e;
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.radiobutton-wrapper .recommended-container p {
  background: var(--background-color);
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 10px;
}

@media screen and (width <= 420px) {
  .radiobutton-wrapper .recommended-container p {
    padding: 0 2px;
    font-size: 8px;
  }
}

.radiobutton-wrapper .radiobutton-input-row {
  font-family: var(--font-family);
  color: var(--text-color);
  align-items: center;
  font-weight: bold;
  display: flex;
}

@media screen and (width <= 420px) {
  .radiobutton-wrapper .radiobutton-input-row {
    padding-top: 5px;
  }
}

.radiobutton-wrapper .radiobutton-input-row .input {
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.radiobutton-wrapper .radiobutton-input-row .input input {
  accent-color: #d43854;
  border: none;
  width: 20px;
  height: 20px;
  margin: 0;
}

.radiobutton-wrapper .radiobutton-input-row .image {
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.radiobutton-wrapper .radiobutton-input-row .image.disabled {
  filter: contrast(0);
}

.radiobutton-wrapper .radiobutton-input-row .image img {
  width: 30px;
  height: 30px;
}

.radiobutton-wrapper .radiobutton-input-row .text {
  word-break: break-all;
}

.radiobutton-wrapper .radiobutton-content-row {
  flex-direction: row;
  justify-content: left;
  display: flex;
}

@media screen and (width <= 500px) {
  .radiobutton-wrapper .radiobutton-content-row {
    flex-direction: column;
  }
}

.radiobutton-wrapper .radiobutton-content-row .textonly {
  width: 100%;
}

.radiobutton-wrapper .radiobutton-content-row .image {
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: flex;
}

.radiobutton-wrapper .radiobutton-content-row .image img {
  width: 175px;
}

.radiobutton-wrapper .radiobutton-content-row.listing {
  flex-direction: column;
}

.radiobutton-wrapper .radiobutton-content-row.listing .step {
  flex-direction: row;
  display: flex;
}

@media screen and (width <= 500px) {
  .radiobutton-wrapper .radiobutton-content-row.listing .step {
    flex-direction: column;
    margin-top: 5px;
  }

  .radiobutton-wrapper .radiobutton-content-row.listing .step p, .radiobutton-wrapper .radiobutton-content-row.listing .step div {
    margin: 2px;
  }
}

.radiobutton-wrapper .radiobutton-content-row.listing .step p, .radiobutton-wrapper .radiobutton-content-row.listing .step div {
  margin-left: 5px;
}

.radiobutton-wrapper .radiobutton-content-row.tabulator {
  width: 75px;
}

.radiobutton-wrapper .radiobutton-content-row.disable-description {
  margin-left: 70px;
}

.radiobutton-wrapper .radiobutton-content-row.disable-description p {
  color: gray;
}

.radiobutton-wrapper .radiobutton-content-row .textarea {
  border-color: var(--menu-border-color);
  resize: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
}

.radiobutton-wrapper .radiobutton-content-row .textarea:focus {
  outline: none !important;
}

@media screen and (width <= 600px) {
  .radiobutton-wrapper .radiobutton-content-row .textarea {
    font-size: 10px;
  }
}

.radiobutton-wrapper.no-background {
  background: none !important;
}

.radiobutton_group_no_more_options {
  margin-left: 29px;
}

.checkbox-wrapper .checkbox-input {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.checkbox-wrapper .checkbox-input p {
  margin: 0 0 0 8px;
}

.checkbox-wrapper .checkbox-input input {
  accent-color: var(--checkbox-color);
  border: none;
}

.checkbox-wrapper .checkbox_message {
  align-self: flex-start;
  width: fit-content;
  margin-top: 10px;
}

.checkbox-wrapper .checkbox_message .checkbox_text {
  border-radius: 5px;
  min-width: 48px;
  margin: 0;
  padding: 8px;
  font-size: 12px;
}

.checkbox-wrapper .checkbox_message .checkbox_text.error {
  background-color: var(--error-background-color);
  color: var(--error-link-color);
}

.checkbox-wrapper .checkbox_message .checkbox_text.warning {
  background-color: var(--warning-background-color);
  color: var(--warning-link-color);
}

.checkbox-wrapper .checkbox_message .checkbox_corner {
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  transform: translate(4px, -7px);
}

.checkbox-wrapper .checkbox_message .checkbox_corner.error {
  border-bottom: 7px solid var(--error-background-color);
}

.checkbox-wrapper .checkbox_message .checkbox_corner.warning {
  border-bottom: 7px solid var(--warning-background-color);
}

.digit {
  background-color: var(--digit-background-color);
  max-width: var(--digit-width);
  height: var(--digit-height);
  border: var(--digit-border-default);
  border-radius: var(--digit-border-radius);
  text-align: center;
  font-size: 16px;
}

.digit:focus-visible {
  border: var(--digit-border-focus);
  outline: none;
}

.digit::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.digit::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.digit[type="number"] {
  -moz-appearance: textfield;
}

.card-group {
  background-color: #fff;
  border: 1px solid #b4b4c1;
  border-radius: 8px;
  padding: 0;
}

.card-group button {
  border-radius: none;
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.card-group-item {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  padding: 16px;
  border-bottom: 1px solid #b4b4c1 !important;
}

.card-group-item.is-clickable {
  cursor: pointer;
}

.card-group-item.parent {
  background-color: var(--card-group-background-color-parent);
}

.card-group-item.parent:first-child {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.card-group-item:last-child {
  border-bottom: none !important;
}

.card-group-item .label {
  text-align: left;
  flex-grow: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
}

.card-group-item .description {
  color: #5e5e72;
  text-align: left;
  width: 100%;
}

.card-group-item .action-wrapper {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.card-group-item .action-wrapper .icon {
  color: var(--link-color);
}

.card-group-item .icon {
  flex-shrink: 0;
}

.card-group-item ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.card-group-item ul strong {
  margin-right: 4px;
}

.card-group-item ul li p, .card-group-item ul li h4 {
  color: var(--text-color-muted);
  display: inline;
}

.card-group-item ul li h4 {
  margin-right: 4px;
}

.card-group-item .toggle-item {
  margin: 0 !important;
}

.badge {
  border-radius: 100px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  flex-grow: 0 !important;
}

.badge.badge-warning {
  background-color: #ffc832;
}

.badge.badge-success {
  color: #fff;
  background-color: #01884c;
}

.badge.badge-info {
  color: #fff;
  background-color: #0f69af;
}

.information-qr {
  color: #4c338c;
  background: #fff;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 906px;
  margin-top: 30px;
  padding: 20px;
  display: flex;
}

.information-qr p.note {
  margin: 0;
}

.information-qr p.note a {
  font-size: 12px;
}

.information-qr .information-qr-zone {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.information-qr .information-qr-zone .qr-code {
  margin-bottom: 15px;
  margin-right: 15px;
}

.information-qr .information-qr-zone .qr-content {
  margin-bottom: 15px;
}

.loading-image-child {
  color: #4c338c;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  display: flex;
}

.loading-image-child .loading-image-child-paragraph {
  margin: 0;
  color: #4c338c !important;
  text-align: left !important;
}

.loading-image-child .loading-image-child-img-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.loading-image-child .loading-image-child-img-wrapper img {
  max-width: 500px;
  height: 100%;
  width: 100% !important;
}

@media screen and (height <= 700px) {
  .loading-image-child .loading-image-child-img-wrapper img {
    max-height: 50vh !important;
  }
}

@media screen and (height <= 900px) {
  .loading-image-child .loading-image-child-img-wrapper img {
    max-height: 65vh;
  }
}

.pagination-wrapper {
  flex-direction: column;
  margin-bottom: -35px;
  display: flex;
}

.pagination-wrapper .pagination-entry {
  display: none;
}

.pagination-wrapper .pagination-entry.active {
  display: block;
}

.pagination-wrapper .pagination-buttons {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination-wrapper .pagination-buttons button {
  background: none;
}

.pagination-wrapper .pagination-buttons button.activeInPagination {
  text-decoration: underline 2px;
}

.pagination-wrapper .pagination-buttons .rightArrowButton {
  transform: rotate(180deg);
}

.passkey-device {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  max-width: 906px;
  padding: 10px;
  font-family: Lato, sans-serif;
  display: flex;
}

.passkey-device--row {
  align-items: center;
  gap: 20px;
  display: flex;
}

.passkey-device--row img {
  width: 30px;
  height: 30px;
}

.passkey-device--title {
  margin-left: 50px;
  font-weight: bold;
}

.passkey-device--device-name * {
  color: #000;
}

.card-group-header {
  background-color: #fff;
}

.card-group-header .icon-wrapper {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  display: flex;
}

.card-group-header .icon-wrapper.success {
  background-color: var(--card-group-header-bakground-success-color);
}

.card-group-header .icon-wrapper.warning {
  background-color: var(--card-group-header-bakground-warning-color);
}

.card-group-header .icon-wrapper.info {
  background-color: var(--card-group-header-bakground-info-color);
}

.card-group-header .title {
  margin-top: 4px;
  font-size: 26px;
  font-weight: bold;
  line-height: 140%;
}

.card-group-header .description {
  color: #5e5e72;
  font-size: 16px;
  line-height: 160%;
}

.card {
  cursor: pointer;
  background-color: #c5bbdc61;
  border-left: 5px solid #4c338d;
  border-radius: 6px;
  flex-flow: wrap;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  padding: 5px 10px;
  display: flex;
}

.card .toggle-container {
  cursor: pointer;
  width: 24px;
  height: 24px;
  animation: 5s fade-out;
  display: inline-block;
}

.card .toggle-container .line {
  -o-transition: all .65s ease;
  background: #4c338c;
  width: 24px;
  height: 2px;
  margin: 5px auto;
  transition: all .65s;
  display: block;
}

.card .toggle-container.inactive .bottom {
  transform: translateY(0)rotateZ(90deg);
}

.card .toggle-container.inactive .top {
  transform: translateY(7px)rotateZ(180deg);
}

.card .toggle-container.active .bottom {
  transform: translateY(0)rotateZ(180deg);
}

.card .toggle-container.active .top {
  transform: translateY(7px)rotateZ(0);
}

.card .headingwrapper {
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  display: flex;
}

.card .headingwrapper h3, .card .headingwrapper h4, .card .headingwrapper h5 {
  text-align: left;
  margin: 0 0 0 10px;
}

.card .hidden {
  display: none !important;
}

@media only screen and (width <= 450px) {
  .card .imgwrapper {
    width: 100%;
  }
}

.card .imgwrapper {
  justify-content: center;
  width: 30%;
  display: flex;
}

@media only screen and (width <= 450px) {
  .card .imgwrapper img img {
    width: 50%;
  }
}

.card .imgwrapper img {
  border: 2px solid #4c338c;
  border-radius: 6px;
  align-self: start;
  width: 100%;
}

.card .card-header-icon {
  margin-right: 5px;
}

@media only screen and (width <= 450px) {
  .card .textwrapper {
    width: 100%;
  }
}

.card .textwrapper {
  flex-direction: row;
  flex: 1;
  align-items: start;
  width: 70%;
  display: flex;
}

.card .textwrapper p {
  margin-top: 0;
}

.card .text-full-with {
  flex-direction: column;
  width: 100%;
}

.card .text-full-with img {
  width: 100%;
  margin-bottom: 10px;
}

.newly-added-device {
  color: #000;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  max-width: 906px;
  padding: 10px;
  font-family: Lato, sans-serif;
  display: flex;
}

.newly-added-device--row {
  align-items: center;
  gap: 20px;
  display: flex;
}

.newly-added-device--row img {
  width: 30px;
  height: 30px;
}

.newly-added-device--title {
  margin-left: 50px;
  font-weight: bold;
}

.newly-added-device--device-name * {
  color: #000;
}

hr {
  border: none;
  width: 100%;
}

hr.light {
  border-bottom: 2px solid #edeeea;
}

hr.dark {
  border-bottom: 2px solid #a6a8a7;
}

.might-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.locked {
  position: fixed;
  inset: 0;
  background-color: #00000080 !important;
}

.feedback-modal-wrapper {
  max-width: 700px;
  animation: 1s forwards moveUp;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 568px) {
  .feedback-modal-wrapper {
    width: 75%;
  }
}

@media screen and (width <= 600px) {
  .feedback-modal-wrapper {
    font-size: 10px;
  }
}

@media only screen and (width >= 568px) and (width <= 896px) {
  .feedback-modal-wrapper {
    width: 50%;
  }
}

.feedback-modal-wrapper .feedback-modal {
  background: #4c338c;
  border-radius: 6px;
  padding: 15px 20px;
}

.feedback-modal-wrapper .feedback-modal p, .feedback-modal-wrapper .feedback-modal h4 {
  color: #f2c24e;
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-exit-button {
  flex-direction: row-reverse;
  justify-content: end;
  display: flex;
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-exit-button .feedback-modal-close {
  width: 20px !important;
  height: 20px !important;
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-button-group {
  justify-content: flex-end;
  gap: 15px;
  margin-top: 25px;
  display: flex;
}

@media screen and (width <= 600px) {
  .feedback-modal-wrapper .feedback-modal .feedback-modal-button-group {
    flex-direction: column-reverse;
    font-size: 10px;
  }

  .feedback-modal-wrapper .feedback-modal .feedback-modal-button-group button {
    width: 100%;
  }

  .feedback-modal-wrapper .feedback-modal .feedback-modal-button-group .feedback-modal-button {
    font-size: 10px;
  }
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-rating {
  margin-top: 25px;
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-rating .rating-wrapper {
  color: #000;
  overflow-wrap: anywhere;
  background-color: #fff;
  border-radius: 6px;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  display: flex;
  overflow: hidden;
}

@media screen and (width <= 600px) {
  .feedback-modal-wrapper .feedback-modal .feedback-modal-rating .rating-wrapper {
    font-size: 10px;
  }
}

@media screen and (width <= 875px) {
  .feedback-modal-wrapper .feedback-modal .feedback-modal-rating .rating-wrapper {
    justify-content: center;
  }
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-rating .rating-label-container {
  flex-grow: 3;
  justify-content: center;
  align-items: center;
  width: 20%;
  display: flex;
}

@media screen and (width <= 875px) {
  .feedback-modal-wrapper .feedback-modal .feedback-modal-rating .rating-label-container {
    display: none;
  }
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-textarea .input-label {
  color: #f2c24e;
  margin-top: 25px;
  margin-bottom: 6px;
}

@media screen and (width <= 600px) {
  .feedback-modal-wrapper .feedback-modal .feedback-modal-textarea .input-label {
    font-size: 10px;
  }
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-textarea .textarea {
  resize: none;
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
}

.feedback-modal-wrapper .feedback-modal .feedback-modal-textarea .textarea:focus {
  outline: none !important;
}

@media screen and (width <= 600px) {
  .feedback-modal-wrapper .feedback-modal .feedback-modal-textarea .textarea {
    font-size: 10px;
  }
}

.feedback-modal-wrapper .feedback-modal .rating-input-wrapper {
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.feedback-modal-wrapper .feedback-modal .rating-input-wrapper .rating-input-container {
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

@media only screen and (width <= 400px) {
  .feedback-modal-wrapper .feedback-modal .rating-input-wrapper .rating-input-container {
    width: 30px;
    height: 30px;
  }
}

.feedback-modal-wrapper .feedback-modal .rating-input-wrapper .rating-input-container .rating-input-container-label {
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}

@media screen and (width <= 600px) {
  .feedback-modal-wrapper .feedback-modal .rating-input-wrapper .rating-input-container .rating-input-container-label {
    font-size: 10px;
    position: absolute;
  }
}

.feedback-modal-wrapper .feedback-modal .rating-input-wrapper .rating-input-container .rating-input-container-input {
  -ms-appearance: none;
  appearance: none;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  margin: 0;
}

@media only screen and (width <= 400px) {
  .feedback-modal-wrapper .feedback-modal .rating-input-wrapper .rating-input-container .rating-input-container-input {
    width: 30px;
    height: 30px;
  }
}

.feedback-modal-wrapper .feedback-modal .rating-input-wrapper .star-icon {
  color: #f2c24e;
  cursor: pointer;
  font-size: 2em;
  position: relative;
}

.feedback-modal-wrapper .feedback-modal .rating-input-wrapper .star-icon-full:before {
  color: #4c338c;
  content: "★";
  font-size: 2em;
  position: absolute;
  left: 0;
}

@-moz-document url-prefix() {
  .feedback-modal-wrapper .feedback-modal .rating-input-wrapper .star-icon {
    font-size: 50px;
    line-height: 34px;
  }
}

.feedback-modal-wrapper .feedback-modal textarea {
  resize: none;
  border: none;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
}

.feedback-modal-wrapper .feedback-modal .rating-label {
  padding: 5px 0;
}

@media screen and (width <= 600px) {
  .feedback-modal-wrapper .feedback-modal .rating-label {
    font-size: 8px;
    position: absolute;
  }
}

.feedback-modal-wrapper .feedback-textarea {
  margin-top: 20px;
}

@keyframes moveUp {
  0% {
    transform: translate(-50%, 100%);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

.onlyOneAuthInfo {
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  max-width: 906px;
  display: flex;
}

.onlyOneAuthInfo .icon-size {
  width: 30px;
  height: 30px;
}

@media screen and (width <= 1150px) {
  .onlyOneAuthInfo .icon-size {
    width: 20px;
    height: 20px;
  }
}

.onlyOneAuthInfo .information {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.onlyOneAuthInfo .information p {
  text-align: unset;
}

.onlyOneAuthInfo .explanation {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

@media screen and (width <= 900px) {
  .onlyOneAuthInfo .explanation {
    flex-direction: column;
  }
}

.onlyOneAuthInfo .explanation .explanation-card {
  background-color: #f3f4f2;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 45%;
  padding: 10px;
  display: flex;
}

@media screen and (width <= 900px) {
  .onlyOneAuthInfo .explanation .explanation-card {
    width: 100%;
  }
}

.onlyOneAuthInfo .explanation .explanation-card p {
  margin: 0;
}

.onlyOneAuthInfo .explanation .explanation-card h3 {
  text-align: center;
  margin: 0;
}

.onlyOneAuthInfo .explanation .current .authInfo {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.onlyOneAuthInfo .explanation .current p {
  margin: 0;
}

.onlyOneAuthInfo .explanation .plus {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.onlyOneAuthInfo .explanation .plus p {
  font-size: 40px;
}

@media screen and (width <= 950px) {
  .onlyOneAuthInfo .explanation .plus p {
    font-size: 20px;
  }
}

.onlyOneAuthInfo .explanation .expected .authenticators {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.onlyOneAuthInfo .explanation .expected .authenticators div {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.passKey {
  border: var(--card-border);
  background-color: #fff;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
}

.passKey .passKey-content-side {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.passKey .passKey-content-wrapper {
  flex-direction: column;
  margin-left: 16px;
  display: flex;
}

.passKey h3, .passKey p {
  margin: 0;
}

.passKey .action-wrapper {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.passKey .action-wrapper button {
  width: fit-content;
}

.passKey .action-wrapper label {
  margin-bottom: 0 !important;
}
/*# sourceMappingURL=index.a427b824.css.map */
