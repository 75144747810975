@use "../../../../assets/styles/_variables";

.pagination-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: -35px;
	
	.pagination-entry {
		display: none;
		
		&.active {
			display: block;
		}
	}
	
	.pagination-buttons {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		
		button {
			background: transparent;
			
			&.activeInPagination {
				text-decoration: underline;
				text-decoration-thickness: 2px;
			}
		}
		
		.rightArrowButton {
			transform: rotate(180deg);
		}
	}
}
