@use "../../../../assets/styles/_variables";

.locked {
	background-color: rgba(0, 0, 0, .5) !important;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

.feedback-modal-wrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	animation: moveUp 1s forwards;
	max-width: 700px;

	@media only screen and (max-width: variables.$screensize-xs) {
		width: 75%;
	}

	@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
		font-size: 10px;
	}

	@media only screen and (min-width: variables.$screensize-xs) and (max-width: variables.$screensize-sm) {
		width: 50%;
	}

	.feedback-modal {
		background: variables.$background-feedback;
		border-radius: 6px;
		padding: 15px 20px;

		p, h4 {
			color: variables.$font-highlight;
		}

		.feedback-modal-exit-button {
			display: flex;
			flex-direction: row-reverse;
			justify-content: end;

			.feedback-modal-close {
				width: 20px !important;
				height: 20px !important;
			}
		}

		.feedback-modal-button-group {
			display: flex;
			justify-content: flex-end;
			margin-top: 25px;
			gap: 15px;

			@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
				font-size: 10px;
				flex-direction: column-reverse;
				
				button {
					width: 100%;
				}
			}

			.feedback-modal-button {
				@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
					font-size: 10px;
				}
			}
		}

		.feedback-modal-rating {
			margin-top: 25px;
			
			.rating-wrapper {
				background-color: variables.$white;
				color: variables.$black;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex: 1;
				padding: 10px;
				border-radius: 6px;
				overflow: hidden;
				overflow-wrap: anywhere;
				font-size: 14px;

				@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
					font-size: 10px;
				}

				@media screen and (max-width: variables.$breakpoint-hide-label-feedbackmodal) {
					justify-content: center;
				}
			}

			.rating-label-container {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-grow: 3;
				width: 20%;

				@media screen and (max-width: variables.$breakpoint-hide-label-feedbackmodal) {
					display: none;
				}

			}

		}

		.feedback-modal-textarea {
			.input-label {
				color: variables.$yellow;
				margin-bottom: 6px;
				margin-top: 25px;

				@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
					font-size: 10px;
				}
			}

			.textarea {
				border-radius: 6px;
				border: none;
				resize: none;
				padding: 10px;
				width: 100%;

				&:focus {
					outline: none !important;
				}

				@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
					font-size: 10px;
				}
			}
		}


		.rating-input-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 5px;
			margin-left: 5px;

			.rating-input-container {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;

				@media only screen and (max-width: 400px) {

					width: 30px;
					height: 30px;
				}

				.rating-input-container-label {
					position: absolute;
					text-align: center;
					user-select: none;

					@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
						font-size: 10px;
						position: absolute;
					}
				}

				.rating-input-container-input {
					appearance: none;
					border-radius: 6px;
					margin: 0;

					width: 40px;
					height: 40px;

					@media only screen and (max-width: 400px) {
						width: 30px;
						height: 30px;
					}
				}
			}

			.star-icon {
				color: variables.$yellow;
				font-size: 2em;
				position: relative;
				cursor: pointer;
			}

			.star-icon-full:before {
				font-size: 2em;
				color: variables.$purple;
				content: '\2605'; /* Full star in UTF-8 */
				position: absolute;
				left: 0;
			}

			@-moz-document url-prefix() { /* Firefox Hack :( */
				.star-icon {
					font-size: 50px;
					line-height: 34px;
				}
			}
		}
		
		textarea {
			border-radius: 6px;
			border: none;
			resize: none;
			width: 100%;
			padding: 10px
		}

		.rating-label {
			padding: 5px 0 5px 0;

			@media screen and (max-width: variables.$breakpoint-mobile-view-progressbar) {
				font-size: 8px;
				position: absolute;
			}
		}
	}
	
	.feedback-textarea {
		margin-top: 20px;
	}
}


@keyframes moveUp {
	0% {
		transform: translate(-50%, 100%);
	}
	100% {
		transform: translate(-50%, -50%);
	}
}
