@use "../../../../assets/styles/variables";

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  
  &.pulledRight {
    float: right;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column-reverse;
    
    &.pulledRight {
      float: unset !important;

      button {
        width: 100%;
      }
    }
  }
}
